import React, { useContext, useEffect, useRef } from 'react'
import '../footerBanner/footerBanner.scss'
import { Context } from '../../App'
import {sendAnalytics} from "../utils/analytics";

function FrooterBanner() {
  const screenWidth = useContext(Context).screenWidth

  const bannerWrapRef = useRef(null)
  const blueRectRef = useRef(null)

  const setHeight = () => {
    blueRectRef.current.style.height = `${
      bannerWrapRef.current.offsetHeight - 50
    }px`
  }

  return (
    <div className="FrooterBannerSection">
      <div ref={bannerWrapRef} className="FrooterBannerSection_Wrap">
        <div className="FrooterBannerSection_Wrap_Main">
          <div className="FrooterBannerSection_Wrap_Main_Left">
            <div className="FrooterBannerSection_Wrap_Main_Left_Text">
              Let's connect to start the conversation
            </div>

            <a
              onClick={() => {
                sendAnalytics('Contact us section', 'tap_lets_connect', '')
              }}
              href="mailto:experience@microsoft.com"
              className="FrooterBannerSection_Wrap_Main_Left_Button"
            >
              Let’s Connect
            </a>
          </div>

          <div className="FrooterBannerSection_Wrap_Main_RightImageWrap">
            <img
              id="footerBanner_section"
              className="FrooterBannerSection_Wrap_Main_RightImage"
              src="./img/footer_banner_2_1.png"
            />
          </div>
        </div>

        <div
          ref={blueRectRef}
          className="FrooterBannerSection_Wrap_BlueRect"
        ></div>
      </div>
    </div>
  )
}

export default FrooterBanner
