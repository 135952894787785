import React, { useContext, useEffect, useRef, useState } from 'react'
import '../header/header.scss'
import { Context } from '../../App'
import { gsap } from 'gsap'
import {sendAnalytics} from "../utils/analytics";

function Header() {
  const screenWidth = useContext(Context).screenWidth

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [shouldCloseMenuRef, setShouldCloseMenuRef] = useState(true)

  const menuRef = useRef(null)

  // --------------------------------------------------

  const clickMenu = () => {
    setIsMenuOpen((el) => !el)
  }

  const scrollToElement = (elem) => {
    const point = document.querySelector(elem)
    const html = document.getElementsByTagName('html')[0]

    html.scroll({
      top: point.offsetTop - (screenWidth > 1080 ? 70 : 80),
      behavior: 'smooth',
    })

    // console.log(point.offsetTop)
  }

  useEffect(() => {
    if (menuRef && menuRef.current && screenWidth < 920) {
      if (isMenuOpen) {
        setShouldCloseMenuRef(false)

        gsap.from(menuRef.current, { backdropFilter: 'blur(0px)' })

        gsap.from('.HeaderSection_Menu_Wrap_Links_El_Gsap', {
          x: -50,
          opacity: 0,
          stagger: 0.1,
        })
      } else {
        gsap.to(menuRef.current, {
          backdropFilter: 'blur(0px)',
          opacity: 0,
          onComplete: () => {
            setShouldCloseMenuRef(true)
          },
        })

        gsap.to('.HeaderSection_Menu_Wrap_Links_El_Gsap', {
          x: -50,
          opacity: 0,
          stagger: 0.1,
        })
      }
    }
  }, [isMenuOpen])

  useEffect(() => {
    if (screenWidth > 920) {
      setIsMenuOpen(false)
      setShouldCloseMenuRef(true)
    }
  }, [screenWidth])

  return (
    <>
      <div className="HeaderSection">
        <div className="HeaderSection_Wrap">
          <div className="HeaderSection_Wrap_Logo HeaderSection_Wrap_El">
            <img src='./img/header_logo_1.png' className="HeaderSection_Wrap_Logo_Img Img1"/>
            <div className="HeaderSection_Wrap_Logo_Img ImgSeparator"></div>
            <img src='./img/header_logo_2.svg' className="HeaderSection_Wrap_Logo_Img Img2"/>
            <div className="HeaderSection_Wrap_Logo_Img ImgSeparator"></div>
            <img src='./img/header_logo_3.svg' className="HeaderSection_Wrap_Logo_Img Img3"/>
          </div>

          {screenWidth > 920 ? (
            <div className="HeaderSection_Wrap_Center HeaderSection_Wrap_El">
              <a
                onClick={() => {
                  sendAnalytics('Header', 'click_menu', 'microsoft_story');
                  scrollToElement('#player_section')
                }}
                // href="#player_section"
                className="HeaderSection_Wrap_Center_El"
              >
                Experiences
              </a>

              <a
                onClick={() => {
                  scrollToElement('#technology_section')
                  sendAnalytics('Header', 'click_menu', 'technology_stack');
                }}
                // href="#technology_section"
                className="HeaderSection_Wrap_Center_El"
              >
                Technology
              </a>

              <a
                onClick={() => {
                  scrollToElement('#footerBanner_section')
                  sendAnalytics('Header', 'click_menu', 'contact_us');
                }}
                // href="#footerBanner_section"
                className="HeaderSection_Wrap_Center_El"
              >
                Contact Us
              </a>
            </div>
          ) : (
            <div className="HeaderSection_Wrap_Center_MenuButton">
              <div
                onClick={clickMenu}
                className="HeaderSection_Wrap_Center_MenuButton_Wrap"
              >
                {isMenuOpen ? (
                  <img src="./img/menu_closed.svg" />
                ) : (
                  <img src="./img/menu_burger.svg" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {isMenuOpen || !shouldCloseMenuRef ? (
        <div ref={menuRef} className="HeaderSection_Menu">
          <div className="HeaderSection_Menu_Wrap">
            <div className="HeaderSection_Menu_Wrap_Title HeaderSection_Menu_Wrap_Links_El_Gsap">
              Menu
            </div>

            <div className="HeaderSection_Menu_Wrap_Links">
              <a
                onClick={() => {
                  setIsMenuOpen(false)
                  scrollToElement('#player_section')
                  sendAnalytics('Header', 'click_menu', 'microsoft_story');
                }}
                // href="#player_section"
                className="HeaderSection_Menu_Wrap_Links_El HeaderSection_Menu_Wrap_Links_El_Gsap"
              >
                Experiences
              </a>

              <a
                onClick={() => {
                  setIsMenuOpen(false)
                  scrollToElement('#technology_section')
                  sendAnalytics('Header', 'click_menu', 'technology_stack');
                }}
                // href="#technology_section"
                className="HeaderSection_Menu_Wrap_Links_El HeaderSection_Menu_Wrap_Links_El_Gsap"
              >
                Technology
              </a>

              <a
                onClick={() => {
                  setIsMenuOpen(false)
                  scrollToElement('#footerBanner_section')
                  sendAnalytics('Header', 'click_menu', 'contact_us');
                }}
                // href="#footerBanner_section"
                className="HeaderSection_Menu_Wrap_Links_El HeaderSection_Menu_Wrap_Links_El_Gsap"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Header
