import React, {useEffect, useState} from 'react'
import BackElips from './components/backElips/BackElips'
import Footer from './components/footer/Footer'
import FrooterBanner from './components/footerBanner/FrooterBanner'
import Header from './components/header/Header'
import Home from './components/home/Home'
import Technology from './components/technology/Technology'
import './settings/settings.scss'
import PlayerNew from './components/playerNew/PlayerNew'
import './App.css'
import './fonts/fonts.css'

export const Context = React.createContext()

function App() {
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const resizeWindow = () => {
      const newScreenWidth = window.innerWidth;

      if (Math.abs(newScreenWidth - screenWidth) > 20 && newScreenWidth !== screenWidth) {
        setScreenWidth(newScreenWidth);
      }
    }

    if (!screenWidth) {
      resizeWindow()
    }
    // window.addEventListener('resize', resizeWindow)

    return () => {
      // window.removeEventListener('resize', resizeWindow)
    }
  }, [screenWidth])

  return (
    <Context.Provider
      value={{ screenWidth: screenWidth }}
    >
      <div className="App">
        <Header />

        <BackElips
          pos_left_1="300px"
          pos_top_1="-200px"
          pos_left_2="-200px"
          color_1="#E4F5FE"
          color_2="#E4F5FE"
        />
        <Home />

        <PlayerNew />
        {screenWidth > 1080 ? (
          <BackElips
            pos_left_1="500px"
            pos_left_2="-600px"
            pos_top_2="50px"
            color_1="#E4F5FE"
            color_2="#daeeff"
          />
        ) : null}

        <Technology />
        {window.innerWidth < 1080 ? (
          <BackElips
            grayElips={true}
            pos_horizontal="start"
            pos_vertical="center"
            pos_left_gray="250px"
            pos_top_gray="-150px"
            height="50vh"
          />
        ) : (
          <></>
        )}

        <FrooterBanner />
        <BackElips
          pos_left_1="200px"
          pos_left_2="-200px"
          pos_top_1="300px"
          pos_top_2="300px"
          // color_1="red"
          // color_2="red"
          height="200px"
          color_1="#E4F5FE"
          color_2="#daeeff"
        />
        <Footer />
      </div>
    </Context.Provider>
  )
}

export default App
