import React from 'react'
import '../home/home.scss'

function Home() {
  return (
    <div className="HomeSection">
      <div className="HomeSection_Wrap">
        <div className="HomeSection_Wrap_Left">
          <div className="HomeSection_Wrap_Left_Small">
            Connected Experiences Built on Microsoft
          </div>
          <div className="HomeSection_Wrap_Left_Big">
            Stories that leverage data, technology and innovation to create
            thought provoking moments for customers.
          </div>
        </div>

        <div className="HomeSection_Wrap_Right">
          <div className="HomeSection_Wrap_Right_Img"></div>

          <div className="HomeSection_Wrap_Right_Img_1 HomeSection_Wrap_Right_Img_El"></div>
          <div className="HomeSection_Wrap_Right_Img_2 HomeSection_Wrap_Right_Img_El"></div>
          <div className="HomeSection_Wrap_Right_Img_3 HomeSection_Wrap_Right_Img_El"></div>
          <div className="HomeSection_Wrap_Right_Img_4 HomeSection_Wrap_Right_Img_El"></div>
        </div>
      </div>
    </div>
  )
}

export default Home
