import React, { useContext, useEffect, useRef, useState } from 'react'
import '../timeBrick/timeBrick.scss'
import { gsap } from 'gsap'
import { Context } from '../../App'
import TimeBricskData from '../../configs/timeBricsData.json'
import { sendAnalytics } from '../utils/analytics'

function TimeBrick(props) {
  const [timeBricskData] = useState(TimeBricskData.data)
  const [timeBricskDuration] = useState(TimeBricskData.duration)
  const screenWidth = useContext(Context).screenWidth

  const { title, subTitle, content, technologies } = props.el
  const videoMainRef = props.videoMainRef

  const isBegin = props.isBegin

  const [isOpenedBrick, setIsOpenedBrick] = useState(false)

  const [isContentOpen, setIsContentOpen] = useState(true)
  const [selectedTimecodem, setSelectedTimecode] = useState(null)
  const [contentHeight, setContentHeight] = useState(0)
  const brickRef = useRef(null)
  const playkRef = useRef(null)
  const contentRef = useRef(null)
  const arrowRef = useRef(null)
  const backRef = useRef(null)
  const animOpen = 0.8
  const animPlay = 0.001
  const [isAnimating, setIsAnimating] = useState(false) // Новое состояние для блокировки анимации
  // ----------------------------------------

  const clickPlay = () => {
    setSelectedTimecode((e) => {
      const res = props.elIndex

      videoMainRef.current.currentTime = timeBricskData[res].time

      return res
    })
  }

  function animClickArrow(res) {
    if (res) {
      gsap.to(brickRef.current, {
        height: '70px',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        duration: animOpen,
      })
      gsap.to(contentRef.current, {
        pointerEvents: 'none',
        opacity: 0,
        duration: animOpen,
        // display: 'none',
      })
    } else {
      gsap.to(brickRef.current, {
        height: screenWidth > 1080 ? contentHeight + 140 : '100%',
        backgroundColor: '#ffffff',
        duration: animOpen,
      })
      gsap.to(contentRef.current, {
        // display: 'flex',
        pointerEvents: 'all',
        opacity: 1,
        duration: animOpen,
      })
    }
  }

  const setWidthOfBack = () => {
    if (backRef && brickRef) {
      backRef.current.style.width = `${brickRef.current.clientWidth}px`
    }
  }

  const hideAllBricksExeptThis = () => {
    const arr = [...brickRef.current.parentElement.children]
    arr.forEach((el, index) => {
      if (index % 2 === 0) {
        if (el.classList[1].slice(-1) != props.elIndex) {
          gsap.to(el, { opacity: 0, duration: 0.3 })
        }
      }
    })
  }
  const showAllBricksExept = () => {
    const arr = [...brickRef.current.parentElement.children]
    arr.forEach((el, index) => {
      if (index % 2 === 0) {
        gsap.to(el, { opacity: 1, duration: 0.3 })
      }
    })
  }

  const animOpenBrickMobile = () => {
    const offset = brickRef.current.offsetTop

    setIsOpenedBrick(true)

    backRef.current.style.zIndex = '2'
    backRef.current.style.pointerEvents = 'all'
    brickRef.current.style.zIndex = '3'

    gsap.to(backRef.current, {
      opacity: 1,
      onComplete: () => {
        hideAllBricksExeptThis()
      },
    })
    gsap.to(brickRef.current, {
      marginTop: -offset,
      delay: 0.5,
      onComplete: () => {
        animClickArrow(false)
        props.scrollDivRef.current.style.overflow = 'hidden'
        props.scrollDivRef.current.scroll({
          top: 0,
          behavior: 'smooth',
        })
      },
    })
  }
  const animCloseBrickMobile = () => {
    animClickArrow(true)

    gsap.to(brickRef.current, {
      marginTop: 0,
    })
    gsap.to(backRef.current, {
      opacity: 0,
      delay: 0.5,
      onComplete: () => {
        setIsOpenedBrick(false)
        showAllBricksExept()

        props.scrollDivRef.current.style.overflowY = 'scroll'
        backRef.current.style.zIndex = '0'
        backRef.current.style.pointerEvents = 'none'
        brickRef.current.style.zIndex = '1'
      },
    })
  }

  const clickArrow = () => {
    if (isAnimating || !isBegin) return

    setIsAnimating(true)

    setIsContentOpen((el) => {
      const res = !el

      if (screenWidth < 1080) {
        if (!res) {
          animOpenBrickMobile()
        } else {
          animCloseBrickMobile()
        }
      } else {
        animClickArrow(res)
      }

      return res
    })

    setTimeout(
      () => {
        setIsAnimating(false)
      },
      screenWidth < 1080 ? 1500 : 800,
    )
  }

  const scrollToThisBrick = (i) => {
    if (i + 1 === timeBricskData.length) {
      setTimeout(() => {
        props.scrollDivRef.current.scroll({
          top: props.scrollDivRef.current.clientHeight,
          behavior: 'smooth',
        })
      }, 200)
    } else {
      setTimeout(() => {
        props.scrollDivRef.current.scroll({
          top:
            i *
              (props.scrollDivRef.current.clientHeight /
                timeBricskData.length) -
            100,
          behavior: 'smooth',
        })
      }, 200)
    }
  }

  const getSelectedTimecode = () => {
    for (let i = 0; i < timeBricskData.length; i++) {
      const currentTime = videoMainRef.current.currentTime

      if (
        timeBricskData.length > i + 1 &&
        timeBricskData[i].time <= currentTime &&
        currentTime < timeBricskData[i + 1].time
      ) {
        setSelectedTimecode(i)
        return i
      }
      if (
        timeBricskData.length <= i + 1 &&
        timeBricskData[i].time <= currentTime &&
        currentTime <= timeBricskDuration
      ) {
        setSelectedTimecode(i)
        return i
      }
    }
  }

  useEffect(() => {
    if (screenWidth < 1080) {
      if (!isOpenedBrick) animClickArrow(true)
    } else {
      scrollToThisBrick(selectedTimecodem)
    }

    if (selectedTimecodem === props.elIndex) {
      if (screenWidth > 1080) {
        setIsContentOpen(false)
        animClickArrow(false)
      }
      gsap.to(playkRef.current, { duration: animPlay, background: '#1daef0' })
    } else {
      if (screenWidth > 1080) {
        setIsContentOpen(true)
        animClickArrow(true)
      }

      gsap.to(playkRef.current, { duration: animPlay, background: '#d1dde5' })
    }
  }, [selectedTimecodem])

  useEffect(() => {
    videoMainRef.current.addEventListener('timeupdate', () => {
      getSelectedTimecode()
    })

    brickRef.current.style.pointerEvents = 'none'
  }, [isBegin])

  useEffect(() => {
    setContentHeight(contentRef.current.scrollHeight)
    setWidthOfBack()
  }, [])

  return (
    <>
      <div
        ref={brickRef}
        className={`TimeBrickSection TimeBrickSection_${props.elIndex} ${
          props.isFullScreen ? 'timeBrick_active' : ''
        }`}
      >
        <div className="TimeBrickSection_Wrap">
          <div
            onClick={clickArrow}
            style={{ cursor: isBegin ? 'pointer' : 'initial' }}
            className="TimeBrickSection_Wrap_Left"
          >
            <div
              onClick={() => {
                clickPlay()
                sendAnalytics(
                  'Experience section',
                  'tap_play_menu',
                  `chapter_${props.elIndex + 1}`,
                )
              }}
              ref={playkRef}
              style={{ cursor: isBegin ? 'pointer' : 'initial' }}
              className="TimeBrickSection_Wrap_Play"
            >
              <img
                src="./img/arrow.svg"
                className="TimeBrickSection_Wrap_Play_Icon"
              />
            </div>

            <div className="TimeBrickSection_Wrap_Text">
              <div className="TimeBrickSection_Wrap_Text_Title">{title}</div>
              <div className="TimeBrickSection_Wrap_Text_SubTitle">
                {subTitle}
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              clickArrow()
              sendAnalytics(
                'Experience section',
                'view_chapter_description',
                `chapter_${props.elIndex + 1}`,
              )
            }}
            style={{ cursor: isBegin ? 'pointer' : 'initial' }}
            className={`TimeBrickSection_Wrap_Arrow ${
              isContentOpen ? 'active' : ''
            }`}
          >
            <img
              ref={arrowRef}
              src={
                screenWidth > 1080
                  ? './img/arrow_top.svg'
                  : isContentOpen
                  ? './img/arrow_top.svg'
                  : './img/menu_closed.svg'
              }
              style={{
                scale:
                  screenWidth > 1080 ? '0.6' : isContentOpen ? '0.4' : '0.6',
              }}
              className="TimeBrickSection_Wrap_Arrow_Icon"
            />
          </div>
        </div>

        <div ref={contentRef} className="TimeBrickSection_Content">
          <div className="TimeBrickSection_Content_Text">{content}</div>

          <div className="TimeBrickSection_Content_Technologies">
            {technologies.length > 0 ? (
              <div className="TimeBrickSection_Content_Technologies_Title">
                Technologies
              </div>
            ) : null}

            <div className="TimeBrickSection_Content_Technologies_List">
              {technologies.map((el, index) => (
                <div
                  className="TimeBrickSection_Content_Technologies_List_El"
                  key={`TBSCTL_${index}`}
                >
                  {el}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div ref={backRef} className="TimeBrickSection_Back"></div>
    </>
  )
}

export default TimeBrick
