import React, { useContext } from 'react'
import { Context } from '../../App';
import '../footer/footer.scss'
import { sendAnalytics } from '../utils/analytics'

function Footer() {
  const screenWidth = useContext(Context).screenWidth

  const scrollToElement = (elem) => {
    const point = document.querySelector(elem)
    const html = document.getElementsByTagName('html')[0]

    html.scroll({
      top: point.offsetTop - (screenWidth > 1080 ? 70 : 80),
      behavior: 'smooth',
    })

    // console.log(point.offsetTop)
  }
  return (
    <div className="FooterSection">
      <div className="FooterSection_Wrap">
        <div className="FooterSection_Wrap_Top">
          <div className="FooterSection_Wrap_Top_Logo"></div>

          <div className="FooterSection_Wrap_Top_Center">
            <a
                onClick={() => {
                  sendAnalytics('Footer', 'click_menu', 'microsoft_story')
                  scrollToElement('#player_section')
                }}
              
              className="FooterSection_Wrap_Top_Center_El"
            >
              Experiences
            </a>
            <a
              onClick={() => {
                sendAnalytics('Footer', 'click_menu', 'technology_stack')
                scrollToElement('#technology_section')
              }}
              className="FooterSection_Wrap_Top_Center_El"
            >
              Technology
            </a>
          </div>

          <div className="FooterSection_Wrap_Top_Socials">
            <a
              onClick={() =>
                sendAnalytics('Footer', 'tap_social_media', 'facebook')
              }
              href="https://www.facebook.com/Microsoft"
              target="_blank"
              className="FooterSection_Wrap_Top_Socials_El"
              rel="noreferrer"
            >
              <img
                src="./img/socials/facebook.svg"
                className="FooterSection_Wrap_Top_Socials_El_Icon"
              />
              <img
                src="./img/socials/facebook_act.svg"
                className="FooterSection_Wrap_Top_Socials_El_IconAct"
              />
            </a>
            <a
              onClick={() =>
                sendAnalytics('Footer', 'tap_social_media', 'linkedin')
              }
              href="https://www.linkedin.com/company/microsoft/"
              target="_blank"
              className="FooterSection_Wrap_Top_Socials_El"
              rel="noreferrer"
            >
              <img
                src="./img/socials/linked_in.svg"
                className="FooterSection_Wrap_Top_Socials_El_Icon"
              />
              <img
                src="./img/socials/linked_in_act.svg"
                className="FooterSection_Wrap_Top_Socials_El_IconAct"
              />
            </a>
            {/* <a className="FooterSection_Wrap_Top_Socials_El">
              <img
                src="./img/socials/instagram.svg"
                className="FooterSection_Wrap_Top_Socials_El_Icon"
              />
              <img
                src="./img/socials/instagram_act.svg"
                className="FooterSection_Wrap_Top_Socials_El_IconAct"
              />
            </div> */}
            <a
                onClick={() => sendAnalytics('Footer', 'tap_social_media', 'twitter')}
                href="https://twitter.com/microsoft"
                target="_blank"
                className="FooterSection_Wrap_Top_Socials_El" rel="noreferrer"
            >
              <img
                src="./img/socials/twiter.svg"
                className="FooterSection_Wrap_Top_Socials_El_Icon"
              />
              <img
                src="./img/socials/twiter_act.svg"
                className="FooterSection_Wrap_Top_Socials_El_IconAct"
              />
            </a>
          </div>
        </div>

        <div className="FooterSection_Wrap_Bottom">
          <div className="FooterSection_Wrap_Bottom_left"></div>
          <div className="FooterSection_Wrap_Bottom_center">
            {new Date().getFullYear()} Copyright © | All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
