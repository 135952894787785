import React, { useContext, useEffect, useRef, useState } from 'react'
import '../timelineBar/timelineBar.scss'
import TimeBricskData from '../../../configs/timeBricsData.json'
import { Context } from '../../../App'
import { gsap } from 'gsap'
import { sendAnalytics } from '../../utils/analytics'
import { iOSCheck, mobileAndTabletCheck } from '../../utils/DeviceDetect'
import technology from '../../technology/Technology'

function TimelineBar({ videoMainRef, isBegin, isFullScreen, setIsFullScreen }) {
  const screenWidth = useContext(Context).screenWidth
  const [timeBricskData] = useState(TimeBricskData.data)
  const [timeBricskDuration] = useState(TimeBricskData.duration)

  const [convertedTimeLine, setConvertedTimeLine] = useState(null)
  const [selectedTimecodem, setSelectedTimecode] = useState(0)

  const [isPlay, setIsPlay] = useState(true)
  const [isVolumeOn, setIsVolumeOn] = useState(true)

  const lineRef = useRef(null)
  const lineProgressRef = useRef(null)
  const playerTimelineRef = useRef(null)

  const volumeRef = useRef(null)
  const volumeProgressRef = useRef(null)
  const volumeProgressWrapRef = useRef(null)

  // -------------------------------------------------
  const secondsToMinutesTime = (sec) => {
    const seconds = sec.toFixed(0)
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    return `${minutes}:${formattedSeconds}`
  }

  const clickPrev = () => {
    if (selectedTimecodem >= 1) {
      setSelectedTimecode((e) => {
        const res = e - 1

        videoMainRef.current.currentTime = timeBricskData[res].time

        return res
      })
    }
  }
  const clickPlay = () => {
    setIsPlay((e) => {
      const res = !e

      if (res) {
        videoMainRef.current.setAttribute('playsinline', '')
        setTimeout(() => videoMainRef.current.play(), 100)
      } else {
        videoMainRef.current.pause()
      }

      return res
    })
  }

  const clickNext = () => {
    if (selectedTimecodem + 1 < timeBricskData.length) {
      setSelectedTimecode((e) => {
        const res = e + 1

        videoMainRef.current.currentTime = timeBricskData[res].time

        return res
      })
    }
  }

  function setWidthOfTimeline() {
    if (lineRef !== null && playerTimelineRef !== null) {
      lineRef.current.style.width = `${
        playerTimelineRef.current.offsetWidth - 5
      }px`
    }
  }

  function setWidthOfVolume(e) {
    if (e) {
      if (volumeProgressRef !== null && volumeRef !== null) {
        volumeProgressRef.current.style.width = `${Math.min(
          (e.target.value * volumeRef.current.offsetWidth) / 100,
          volumeRef.current.offsetWidth,
        )}px`
        volumeProgressWrapRef.current.style.width = `${volumeRef.current.offsetWidth}px`
      }
    } else {
      if (volumeProgressRef !== null && volumeRef !== null) {
        volumeProgressWrapRef.current.style.width = `${volumeRef.current.offsetWidth}px`
      }
    }
  }

  const muteAudio = () => {
    if (isVolumeOn) {
      volumeProgressRef.current.style.width = `0px`
      volumeProgressWrapRef.current.style.width = 0
      volumeRef.current.value = 0
      videoMainRef.current.volume = 0
      videoMainRef.current.muted = true
      setIsVolumeOn(false)
    } else {
      volumeProgressRef.current.style.width = `${volumeRef.current.offsetWidth}px`
      volumeRef.current.value = 100
      videoMainRef.current.volume = 1
      videoMainRef.current.muted = false
      setIsVolumeOn(true)
    }
  }

  useEffect(() => {
    if (isFullScreen) {
      gsap.to('.PlayerNewSection_Wrap_Main_Right_Wrap', { marginTop: '0vw' })
    } else {
      gsap.to('.PlayerNewSection_Wrap_Main_Right_Wrap', { marginTop: '6vw' })
    }
    const timeout1 = setTimeout(() => {
      setWidthOfTimeline()
      setWidthOfVolume()
    }, 100)
    const timeout2 = setTimeout(() => {
      setWidthOfTimeline()
      setWidthOfVolume()
    }, 1000)

    return () => {
      timeout1 && clearTimeout(timeout1)
      timeout2 && clearTimeout(timeout2)
    }
  }, [isFullScreen])

  useEffect(() => {
    const removeFullScreen = (e) => {
      setIsFullScreen((prev) => !prev)
    }

    document.addEventListener('webkitfullscreenchange', removeFullScreen)
    document.addEventListener('fullscreenchange', removeFullScreen)
    document.addEventListener('mozfullscreenchange', removeFullScreen)
    document.addEventListener('msfullscreenchange', removeFullScreen)

    return () => {
      document.removeEventListener('webkitfullscreenchange', removeFullScreen)
      document.removeEventListener('fullscreenchange', removeFullScreen)
      document.addEventListener('mozfullscreenchange', removeFullScreen)
      document.addEventListener('msfullscreenchange', removeFullScreen)
    }
  }, [])

  const clickFullScreen = (e) => {
    e.preventDefault()

    if (iOSCheck()) {
      const video = document.getElementById('main_video')
      video.pause()
      video.style.opacity = 0
      video.removeAttribute('playsinline')
      setTimeout(() => {
        video.play()
        video.removeAttribute('controls')
      }, 200)
      return
    }

    const fullScreenSelector = mobileAndTabletCheck()
      ? '#main_video'
      : '.testBlock'
    const video = document.querySelector(fullScreenSelector)

    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitIsFullScreen ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    if (fullscreenElement) {
      exitFullscreen(video)
    } else {
      launchIntoFullscreen(video)
    }
  }

  function exitFullscreen(element) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else {
      element.classList.remove('fullscreen')
    }
  }

  function launchIntoFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    } else {
      element.classList.add('fullscreen')
    }
  }

  const getSelectedTimecode = () => {
    for (let i = 0; i < timeBricskData.length; i++) {
      const currentTime = videoMainRef.current.currentTime

      if (
        timeBricskData.length > i + 1 &&
        timeBricskData[i].time <= currentTime &&
        currentTime < timeBricskData[i + 1].time
      ) {
        // console.log('1', i)
        setSelectedTimecode(i)
      }
      if (
        timeBricskData.length <= i + 1 &&
        timeBricskData[i].time <= currentTime &&
        currentTime <= timeBricskDuration
      ) {
        // console.log('2', i)
        setSelectedTimecode(i)
      }
    }
  }

  //////////////////////////////////////////////////

  useEffect(() => {
    setConvertedTimeLine((el) => {
      const def = timeBricskData.map((el, index) => {
        if (index + 1 === timeBricskData.length) {
          return {
            start: el.time,
            duration: timeBricskDuration - el.time,
            percentage:
              ((timeBricskDuration - el.time) / timeBricskDuration) * 100,
          }
        }

        return {
          start: el.time,
          duration: timeBricskData[index + 1].time - el.time,
          percentage:
            ((timeBricskData[index + 1].time - el.time) / timeBricskDuration) *
            100,
        }
      })

      return def
    })
  }, [])

  useEffect(() => {
    videoMainRef.current.addEventListener('timeupdate', () => {
      getSelectedTimecode()

      setIsPlay(!videoMainRef.current.paused)

      if (
        Math.floor(videoMainRef.current.currentTime) >=
        Math.floor(videoMainRef.current.duration) - 1
      ) {
        const fullScreenSelector = mobileAndTabletCheck()
          ? '#main_video'
          : '.testBlock'
        const video = document.querySelector(fullScreenSelector)

        const fullscreenElement =
          document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitIsFullScreen ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement

        if (fullscreenElement) {
          exitFullscreen(video)
        }
      }
    })

    videoMainRef.current.addEventListener('pause', () => setIsPlay(false))
  }, [isBegin])

  //////////////////////////////////////////////////

  const SmallDiv = (props) => {
    const [screenWidth, setScreenWidth] = useState(0)
    useEffect(() => {
      const resizeWindow = () => {
        const newScreenWidth = window.innerWidth

        if (
          Math.abs(newScreenWidth - screenWidth) > 20 &&
          newScreenWidth !== screenWidth
        ) {
          setScreenWidth(newScreenWidth)
        }
        setWidthOfTimeline()
        setWidthOfVolume()
      }

      if (!screenWidth) {
        resizeWindow()
      }
      window.addEventListener('resize', resizeWindow)

      return () => {
        // window.removeEventListener('resize', resizeWindow)
      }
    }, [screenWidth])
    return (
      <div
        className={`TimelineBarSection_Section_TimeLine_Line_El TimelineBarSection_Section_TimeLine_Line_El_${props.elIndex}`}
        style={{ width: `${props.el.percentage + 2.5}%` }}
      >
        {props.elIndex !== 0 ? (
          <div className="TimelineBarSection_Section_TimeLine_Line_El_Shape"></div>
        ) : null}
      </div>
    )
  }

  return (
    <>
      <div className="TimelineBarSection">
        <div className="TimelineBarSection_Wrap">
          <div
            style={{
              cursor: isBegin ? 'pointer' : 'initial',
              pointerEvents: isBegin ? 'all' : 'none',
            }}
            className="TimelineBarSection_Section_PrevPauseNext"
          >
            <img
              onClick={() => {
                clickPrev()
                sendAnalytics('Experience section', 'tap_arrow_previous', '')
              }}
              src="./img/playerController/prev.svg"
              className="TimelineBarSection_Section_PrevPauseNext_Prev"
            />
            <img
              onClick={clickPlay}
              src={
                isPlay
                  ? './img/playerController/play.svg'
                  : './img/playerController/pause.svg'
              }
              className="TimelineBarSection_Section_PrevPauseNext_Pause"
            />
            <img
              onClick={() => {
                clickNext()
                sendAnalytics('Experience section', 'tap_arrow_next', '')
              }}
              src="./img/playerController/next.svg"
              className="TimelineBarSection_Section_PrevPauseNext_Next"
            />
          </div>

          <div
            style={{
              cursor: isBegin ? 'pointer' : 'initial',
              pointerEvents: isBegin ? 'all' : 'none',
            }}
            className="TimelineBarSection_Section_TimeLine"
          >
            <input
              ref={playerTimelineRef}
              type="range"
              min={0}
              max={timeBricskDuration}
              className="TimelineBarSection_Section_TimeLine_Input"
            />

            <div
              ref={lineRef}
              className="TimelineBarSection_Section_TimeLine_Line"
            >
              <div
                ref={lineProgressRef}
                className="TimelineBarSection_Section_TimeLine_Line_Progress"
              ></div>

              <div className="TimelineBarSection_Section_TimeLine_Line_Separators">
                {convertedTimeLine !== null &&
                  convertedTimeLine.map((el, index) => (
                    <SmallDiv key={`TCSTLL_${index}`} elIndex={index} el={el} />
                  ))}
              </div>
            </div>
          </div>

          <div className="TimelineBarSection_Section_TimeLine_Time">
            <div className="TimelineBarSection_Section_TimeLine_Time_Left">
              -:--
            </div>
            <span className="TimelineBarSection_Section_TimeLine_Time_RightColor">
              /
            </span>
            <div className="TimelineBarSection_Section_TimeLine_Time_Right TimelineBarSection_Section_TimeLine_Time_RightColor">
              -:--
            </div>
          </div>

          <div
            style={{
              cursor: isBegin ? 'pointer' : 'initial',
              pointerEvents: isBegin ? 'all' : 'none',
            }}
            className="TimelineBarSection_Section_Volume"
          >
            <img
              onClick={muteAudio}
              src="./img/playerController/volume.svg"
              className={`TimelineBarSection_Section_Volume_Icon ${
                !isVolumeOn ? 'disabled' : ''
              }`}
            />

            <div
              style={{ display: screenWidth > 1080 ? 'flex' : 'none' }}
              className="TimelineBarSection_Section_Volume_InputWrap"
            >
              <input
                onChange={setWidthOfVolume}
                ref={volumeRef}
                disabled={!isVolumeOn}
                type="range"
                className="TimelineBarSection_Section_Volume_Input"
              />
              <div
                ref={volumeProgressWrapRef}
                className="TimelineBarSection_Section_Volume_InputWrap_ProgressWrap"
              >
                <div
                  ref={volumeProgressRef}
                  className="TimelineBarSection_Section_Volume_InputWrap_Progress"
                ></div>
              </div>
            </div>
          </div>

          <div
            onClick={(e) => {
              clickFullScreen(e)
              sendAnalytics('Experience section', 'tap_full_screen', '')
            }}
            style={{
              cursor: isBegin ? 'pointer' : 'initial',
              pointerEvents: isBegin ? 'all' : 'none',
            }}
            className="TimelineBarSection_Section_fullScreen"
          >
            <img
              src="./img/playerController/full_screen.svg"
              className="TimelineBarSection_Section_fullScreen_Icon"
            />
          </div>
        </div>
      </div>

      <div id="selectedTimecode"></div>
    </>
  )
}

export default React.memo(TimelineBar)
