import React, {
  forwardRef,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import '../technology/technology.scss'
import { gsap } from 'gsap'
import TechnologyData from '../../configs/technologyData.json'
import { Context } from '../../App'
import { sendAnalytics } from '../utils/analytics'

const Card = memo(
  forwardRef(({ el }, cardRef) => {
    return (
      <div ref={cardRef} className="CardSection">
        <div className="CardSection_Container">
          <div className="CardSection_Container_Wrap">
            <div className="CardSection_Container_Wrap_Top">
              <div className="CardSection_Container_Wrap_Top_Logo">
                <img
                  className="CardSection_Container_Wrap_Top_Logo_Icon"
                  src={`./img/technology/${el.img}`}
                />
              </div>

              <div className="CardSection_Container_Wrap_Top_Title">
                {el.title}
              </div>
            </div>

            <div className="CardSection_Container_Wrap_Content">
              {el.content}
            </div>

            <a
              onClick={() =>
                sendAnalytics('Technology section', 'tap_learn_more', el.title)
              }
              href={el.url}
              target="_blank"
              className="CardSection_Container_Wrap_LearnMore"
            >
              Learn More
              <img
                className="CardSection_Container_Wrap_LearnMore_Icon"
                src="./img/arrow_right.svg"
              />
            </a>
          </div>
        </div>
      </div>
    )
  }),
)

function Technology() {
  // const screenWidth = useContext(Context).screenWidth
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [technologyData] = useState(TechnologyData.data)

  const technologySectionRef = useRef(null)
  const technologyContainerRef = useRef(null)
  const wrapSeeMoreRef = useRef(null)

  const scrollWrapRef = useRef(null)
  const cardRef = useRef(null)
  const wormRef = useRef(null)
  const wormWrapRef = useRef(null)

  const [currentCardIndex, setCurrentCardIndex] = useState(0)
  // ==============================================

  const clickSeeMore = () => {
    technologyContainerRef.current.style.paddingBottom = '110px'

    gsap.to(technologySectionRef.current, {
      height: 'fit-content',
      duration: 4,
    })
    gsap.to(technologyContainerRef.current, {
      height: 'fit-content',
      duration: 4,
    })
    gsap.to(wrapSeeMoreRef.current, { opacity: 0, display: 'none' })
  }

  useEffect(() => {
    if (scrollWrapRef && scrollWrapRef.current) {
      scrollWrapRef.current.addEventListener('scroll', function () {
        const wormStep = 20
        const cardGap = screenWidth > 700 ? -20 : 0

        const scrollTop = scrollWrapRef.current.scrollLeft
        const cardWidth = cardRef.current.clientWidth + cardGap
        const currentIndex = Math.floor(scrollTop / cardWidth)
        // console.log((scrollTop / cardWidth).toFixed(2), cardWidth, screenWidth)

        setCurrentCardIndex((e) => {
          if (currentIndex < e) {
            gsap.to(wormRef.current, {
              x: currentIndex * wormStep,
              width: 30,
              onComplete: () => {
                console.log('left')
                gsap.to(wormRef.current, { width: 10 })
              },
            })
            return currentIndex
          }
          if (currentIndex > e) {
            gsap.to(wormRef.current, {
              width: 30,
              onComplete: () => {
                console.log('right')

                gsap.to(wormRef.current, {
                  x: currentIndex * wormStep,
                  width: 10,
                })
              },
            })
            return currentIndex
          }
          return e
        })
      })
    }
  }, [])

  useEffect(() => {
    const resizeWindow = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  return (
    <>
      <div
        ref={technologySectionRef}
        id="technology_section"
        className="TechnologySection"
      >
        <div
          ref={technologyContainerRef}
          className="TechnologySection_Container"
        >
          <div className="TechnologySection_Wrap">
            <div className="TechnologySection_Wrap_Title">
              <div className="TechnologySection_Wrap_Title_Big">Technology</div>

              <div className="TechnologySection_Wrap_Title_Small">
                Activate memorable experiences by leveraging Microsoft's
                platform to enable everyday tasks and life's biggest moments.
              </div>
            </div>

            {screenWidth > 1100 ? (
              <div className="TechnologySection_Wrap_Main">
                {technologyData.map((el, index) => (
                  <Card key={`TSWM_${index}`} el={el} ref={cardRef} />
                ))}
              </div>
            ) : (
              <>
                <div
                  ref={scrollWrapRef}
                  className="TechnologySection_Wrap_Main_Scroll"
                >
                  <div className="TechnologySection_Wrap_Main">
                    {technologyData.map((el, index) => (
                      <Card key={`TSWM_${index}`} el={el} ref={cardRef} />
                    ))}
                  </div>
                </div>

                <div className="TechnologySection_Wrap_Main_Dots">
                  <div className="TechnologySection_Wrap_Main_Dots_Wrap">
                    {technologyData.map((el, index) => (
                      <div
                        className="TechnologySection_Wrap_Main_Dots_Wrap_El"
                        key={`TSWMDE_${index}`}
                      ></div>
                    ))}

                    <div
                      ref={wormWrapRef}
                      className="TechnologySection_Wrap_Main_Dots_Wrap_WormWrap"
                    >
                      <div
                        ref={wormRef}
                        className="TechnologySection_Wrap_Main_Dots_Wrap_Worm"
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* {screenWidth > 1100 ? (
            
          ) : null} */}
          <div ref={wrapSeeMoreRef} className="TechnologySection_Wrap_SeeMore">
            <div className="TechnologySection_Wrap_SeeMore_Wrap">
              <div
                onClick={clickSeeMore}
                className="TechnologySection_Wrap_SeeMore_Wrap_Button"
              >
                See More
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Technology
