import React, { useContext } from 'react'
import '../backElips/backElips.scss'
import { Context } from '../../App'

function BackElips({
  grayElips = false,
  pos_horizontal,
  pos_vertical,
  pos_left_gray = '0px',
  pos_top_gray = '0px',
  pos_left_1 = '0px',
  pos_top_1 = '0px',
  pos_left_2 = '0px',
  pos_top_2 = '0px',
  color_1 = '#fff',
  color_2 = '#fff',
  height = '100vh',
}) {
  const screenWidth = useContext(Context).screenWidth

  return (
    <div
      className="BgElips"
      style={{
        justifyContent: pos_horizontal,
        alignItems: pos_vertical,
        height: height,
      }}
    >
      {grayElips ? (
        <div
          className="TechnologySection_Elips"
          style={{
            left: pos_left_gray,
            top: pos_top_gray,
          }}
        ></div>
      ) : (
        <>
          {' '}
          <div
            style={{
              left: pos_left_1,
              top: pos_top_1,
              boxShadow: ` -30px 0px 300px 200px ${color_1}`,
            }}
            className="BgElips_Elips"
          ></div>
          {screenWidth > 920 ? (
            <div
              style={{
                left: pos_left_2,
                top: pos_top_2,
                boxShadow: ` -30px 0px 300px 200px ${color_2}`,
              }}
              className="BgElips_Elips"
            ></div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default BackElips
