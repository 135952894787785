import React, {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import '../playerNew/playerNew.scss'
import TimeBricskData from '../../configs/timeBricsData.json'
import { gsap } from 'gsap'
import TimelineBar from './timelineBar/TimelineBar'
import TimeBrick from '../timeBrick/TimeBrick'
import { Context } from '../../App'
import { sendAnalytics } from '../utils/analytics'
import { iOSCheck, mobileAndTabletCheck } from '../utils/DeviceDetect'

const StartVideo = memo(
  forwardRef((_, videoStartRef) => {
    const width = window.innerWidth
    return (
      <video
        ref={videoStartRef}
        className="PlayerNewSection_Wrap_Main_Video PlayerNewSection_Wrap_Main_VideoStart"
        playsInline
        autoPlay
        loop
        muted
        controls={false}
        poster="./img/poster.png"
      >
        <source
          src={`./video/start${width > 1080 ? '' : '_mob'}.mp4`}
          type="video/mp4"
        />
      </video>
    )
  }),
  () => true,
)

const MainVideo = forwardRef(
  (
    {
      isBegin,
      secondsToMinutesTime,
      returnToIntro,
      testWrap,
      setIsBegin,
      isFullScreen,
    },
    videoMainRef,
  ) => {
    useEffect(() => {
      const video = document.getElementById('main_video')
      const timeCountLeft = document.querySelector(
        '.TimelineBarSection_Section_TimeLine_Time_Left',
      )
      const timeCountRight = document.querySelector(
        '.TimelineBarSection_Section_TimeLine_Time_Right',
      )
      const input = document.querySelector(
        '.TimelineBarSection_Section_TimeLine_Input',
      )
      const volume = document.querySelector(
        '.TimelineBarSection_Section_Volume_Input',
      )
      const line = document.querySelector(
        '.TimelineBarSection_Section_TimeLine_Line',
      )
      const lineProgress = document.querySelector(
        '.TimelineBarSection_Section_TimeLine_Line_Progress',
      )

      // video.removeAttribute('playsinline')

      if (!isBegin) {
        input.value = 0
        volume.value = 100
      }

      volume.addEventListener('input', () => {
        video.volume = volume.value / 100
      })

      input.addEventListener('input', () => {
        let diff = window.innerWidth <= 1080 ? 10 : 0
        video.currentTime = input.value

        lineProgress.style.width = `${
          (input.value * (line.clientWidth - diff)) / video.duration
        }px`
      })

      video.addEventListener('timeupdate', () => {
        let diff = window.innerWidth <= 1080 ? 10 : 0

        input.value = video.currentTime
        timeCountLeft.innerHTML = secondsToMinutesTime(video.currentTime)
        timeCountRight.innerHTML = secondsToMinutesTime(video.duration)

        lineProgress.style.width = `${
          (video.currentTime * (line.clientWidth - diff)) / video.duration
        }px`

        // if (video.currentTime >= video.duration - 2) {

        // }
        if (video.currentTime >= video.duration - 1) {
          returnToIntro()
          video.currentTime = 0
          setIsBegin(false)
        }
      })

      volume.addEventListener('volumechange', () => {
        video.volume = video.volume * 100
      })

      if (iOSCheck()) {
        video.addEventListener('play', () => {
          console.log('PLAY')
        })
        video.addEventListener('pause', () => {
          console.log('PAUSE')
          video.removeAttribute('controls')

          if (video.getAttribute('playsinline') !== '') {
            // video.removeAttribute('playsinline')
            setTimeout(() => (video.style.opacity = 1), 50)
          }
        })
      }
    }, [])

    const setHeight = () => {
      if (isFullScreen)
        return {
          height: '100vh',
        }

      return {
        position: 'relative',
      }
    }

    const width = window.innerWidth
    return (
      <video
        ref={videoMainRef}
        id="main_video"
        playsInline
        // muted
        controls={false}
        className={`PlayerNewSection_Wrap_Main_Video PlayerNewSection_Wrap_Main_VideoMain ${
          isFullScreen && mobileAndTabletCheck() ? 'testBlock_active' : ''
        }`}
        style={{
          width: `${isFullScreen ? '100vw' : '100%'}`,
          ...setHeight(),
        }}
      >
        <source
          src={`./video/video${width > 1080 ? '' : '_mob'}.mp4`}
          type="video/mp4"
        />
      </video>
    )
  },
)

function PlayerNew() {
  const screenWidth = useContext(Context).screenWidth

  const [timeBricskData] = useState(TimeBricskData.data)
  const [timeBricskDuration] = useState(TimeBricskData.duration)

  const videoStartRef = useRef(null)
  const videoMainRef = useRef(null)
  const blurBoxRef = useRef(null)
  const testWrap = useRef(null)
  const scrollDivRef = useRef(null)
  const dummyRef = useRef(null)
  const dummyTextRef = useRef(null)

  const title1Ref = useRef(null)
  const title2Ref = useRef(null)
  const title3Ref = useRef(null)

  const [isButtonHide, setIsButtonHide] = useState(false)
  const [isBegin, setIsBegin] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const returnToIntro = () => {
    if (isFullScreen) {
      setIsFullScreen((prev) => !prev)
    }
    gsap.to(videoMainRef.current, { opacity: 0 })
    videoMainRef.current.pause()

    gsap.to(blurBoxRef.current, {
      opacity: 1,
      onComplete: () => {
        // setIsBegin(false)

        gsap.to('.TimelineBarSection', { opacity: 0 })

        gsap.to(blurBoxRef.current, {
          opacity: 0,
        })

        // gsap.from(videoMainRef.current, {
        //   opacity: 0,
        // })
      },
    })

    setIsButtonHide(false)
    autoSetHeightOfDummy()

    document.querySelector(
      '.PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap',
    ).style.overflowY = 'hidden'

    gsap.to('.PlayerNewSection_Wrap_Main_Right_Wrap_Button', {
      opacity: 1,
      pointerEvents: 'all',
    })

    gsap.fromTo(
      '.TimeBrickSection',
      { opacity: 1, y: 0, stagger: 0.1 },
      { opacity: 0, y: 70, stagger: 0.1 },
    )
  }

  const clickBeginButton = () => {
    videoMainRef.current.play()

    setIsButtonHide(true)

    videoMainRef.current.style.opacity = '1'

    gsap.to(blurBoxRef.current, {
      opacity: 1,
      onComplete: () => {
        // setIsBegin(true)

        gsap.to('.TimelineBarSection', { opacity: 1 })

        gsap.to(blurBoxRef.current, {
          opacity: 0,
        })

        gsap.from(videoMainRef.current, {
          // opacity: 0,
          onComplete: () => {
            if (videoMainRef.current) {
              setIsBegin(true)
              // videoMainRef.current.play()
            }
          },
        })
      },
    })

    gsap.set('.PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_El', {
      '-webkit-transform': 'translateZ(0)',
      transform: 'translateZ(0)',
      'backface-visibility': 'hidden',
      'will-change': 'transform',
    })

    document.querySelector(
      '.PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap',
    ).style.overflowY = 'scroll'

    gsap.to('.PlayerNewSection_Wrap_Main_Right_Wrap_Button', {
      opacity: 0,
      pointerEvents: 'none',
    })

    gsap.fromTo(
      '.TimeBrickSection',
      { opacity: 0, y: 70, stagger: 0.1 },
      { opacity: 1, y: 0, stagger: 0.1 },
    )
  }

  function autoSetHeightOfDummy() {
    const dummyText = document.querySelectorAll('.dummy_text')
    if (isButtonHide) {
      dummyRef.current.style.flex = '100%'
      dummyRef.current.style.marginBottom = '0px'

      dummyText.forEach((el) => {
        el.style.flex = 'auto'
      })
    } else {
      dummyRef.current.style.flex = '0'
      dummyRef.current.style.marginBottom = '100px'

      dummyText.forEach((el) => {
        el.style.flex = '0'
      })
    }
  }

  useEffect(() => {
    if (dummyRef && scrollDivRef) {
      autoSetHeightOfDummy()
    }
  })

  ///////////////////////////////////////////////////

  const Wrapper = useCallback(
    ({ children }) => {
      if (screenWidth > 1080) {
        return children
      }
      return (
        <div className="PlayerNewSection_Wrap_Main_Adaptive">{children}</div>
      )
    },
    [screenWidth],
  )

  const secondsToMinutesTime = (sec) => {
    const seconds = sec.toFixed(0)
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    return `${minutes}:${formattedSeconds}`
  }

  return (
    <div
      id="player_section"
      className={`PlayerNewSection ${isFullScreen ? 'playerNew_active' : ''}`}
    >
      <div className="PlayerNewSection_Wrap">
        <div
          ref={testWrap}
          className={`PlayerNewSection_Wrap_Main testBlock ${
            isFullScreen ? 'testBlock_active' : ''
          }`}
        >
          <Wrapper>
            <MainVideo
              {...{
                isBegin,
                secondsToMinutesTime,
                returnToIntro,
                isFullScreen,
                testWrap,
                setIsBegin,
              }}
              ref={videoMainRef}
            />
            {!isBegin ? <StartVideo ref={videoStartRef} /> : null}

            <div
              ref={blurBoxRef}
              className="PlayerNewSection_Wrap_Main_BlurBox"
            >
              <div className="PlayerNewSection_Wrap_Main_BlurBox_Glass"></div>
            </div>
          </Wrapper>
          <div className="PlayerNewSection_Wrap_Main_BlurBox_Timeline">
            <TimelineBar
              videoMainRef={videoMainRef}
              isBegin={isBegin}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
            />
          </div>

          <div className="PlayerNewSection_Wrap_Main_Right">
            <div className="PlayerNewSection_Wrap_Main_Right_Wrap">
              <div className="PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer">
                <div className="PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_Big">
                  {/* <div>Connected Experiences<br/>
                  Built on Microsoft
                  </div> */}
                  <div
                    ref={title1Ref}
                    className="PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_Big_1 PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_El"
                  >
                    <div> Connected Experiences</div>
                    <div className="dummy_text"></div>
                  </div>

                  <div
                    ref={title2Ref}
                    className="PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_Big_2 PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_El"
                  >
                    <div>Built on Microsoft</div>
                    <div className="dummy_text"></div>
                  </div>

                  <div
                    ref={title3Ref}
                    className="PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_Small PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_El"
                  >
                    <div>Get Grandpa to Turf Moor</div>
                    <div className="dummy_text"></div>
                  </div>
                </div>
              </div>

              <div
                onClick={() => {
                  clickBeginButton()
                  sendAnalytics(
                    'Experience section',
                    'tap_begin',
                    'introductionSc_1',
                  )
                }}
                className="PlayerNewSection_Wrap_Main_Right_Wrap_Button"
              >
                Tap to Begin
                <img
                  src="./img/arrow.svg"
                  className="PlayerNewSection_Wrap_Main_Right_Wrap_Button_Icon"
                />
              </div>

              <div ref={dummyRef} className="dummy"></div>

              <div className="PlayerNewSection_Wrap_Main_Right_Wrap_Cards">
                <div
                  ref={scrollDivRef}
                  className="PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap"
                >
                  {timeBricskData.map((el, index) => {
                    return (
                      <TimeBrick
                        key={`PSWRMTB_${index}`}
                        el={el}
                        elIndex={index}
                        videoMainRef={videoMainRef}
                        scrollDivRef={scrollDivRef}
                        isBegin={isBegin}
                        isFullScreen={isFullScreen}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PlayerNew)
